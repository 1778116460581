@font-face {
  font-family: Nunito Sans;
  src: url(/public/utils/Fonts/nunito-sans-webfont/NunitoSans-Regular.woff);
}

@font-face {
  font-family: Snugle;
  src: url(/public/utils/Fonts/Snugle/Snugle.ttf);
}

.loader {
  --c: no-repeat linear-gradient(#f57c00 0 0);
  background: var(--c), var(--c), var(--c), var(--c), var(--c), var(--c),
    var(--c), var(--c), var(--c);
  background-size: 16px 16px;
  animation: l32-1 1s infinite, l32-2 1s infinite;
}

@keyframes l32-1 {

  0%,
  100% {
    width: 45px;
    height: 45px;
  }

  35%,
  65% {
    width: 65px;
    height: 65px;
  }
}

@keyframes l32-2 {

  0%,
  40% {
    background-position: 0 0, 0 50%, 0 100%, 50% 100%, 100% 100%, 100% 50%,
      100% 0, 50% 0, 50% 50%;
  }

  60%,
  100% {
    background-position: 0 50%, 0 100%, 50% 100%, 100% 100%, 100% 50%, 100% 0,
      50% 0, 0 0, 50% 50%;
  }
}

@media (min-width: 1560px) {
  .partnerWidth {
    width: 80% !important;
  }
}

* {
  font-family: Nunito Sans !important;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flexEnd {
  display: flex;
  justify-content: end;
  align-items: center;
}

.flexBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flexStart {
  display: flex;
  justify-content: start;
  align-items: center;
}

.fontSnugle {
  font-family: Snugle !important;
}

/* ::-webkit-scrollbar {
  display: none;
} */

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.inputClassCountry {
  padding: 20px 20px 20px 20px !important;
  width: 100% !important;
  border: 1px solid #1a130c3d !important;
  border-radius: 12px !important;
  padding-left: 60px !important;
  background: transparent !important;
  height: 56px !important;
}

.inputClassCountry:hover {
  background: #fff3e8 !important;
  border: none !important;
}

.inputClassCountry.error {
  border: 1px solid red;
}

.inputClassCountry:focus {
  outline: none !important;
  box-shadow: none !important;
}

.flag-dropdown {
  background-color: transparent !important;
  border: 0px !important;
  text-align: start;
  border-radius: 12px !important;
}

.flag-dropdown .selected-flag {
  padding: 0px !important;
  width: 60px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.flag-dropdown .selected-flag:hover {
  border: 1px solid #1a130c3d;
  border-right: none;
  outline: none;
  border-radius: 12px 0 0 12px;
}

.removeDefault.MuiGrid-item {
  padding: 0 !important;
}

.MuiListItemIcon-root {
  min-width: 35px !important;
}

/* Button Hover */
.btnHover:hover {
  padding: 5px 15px;
  outline: 1px solid #f57c00 !important;
  border-radius: 8px;
  color: "#f57c00";
}

.btnHover {
  padding: 5px 15px;
}

.link:hover {
  color: "#f57c00";
}

a.link:hover {
  color: "#f57c00" !important;
}

.link {
  position: relative;
  color: inherit;
  text-decoration: none;
  display: inline-block;
}

.link::before {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 50%;
  background-color: #f57c00;
  transition: all 0.4s ease;
  transform: translateX(-50%);
}

.link:hover::before {
  width: 100%;
  left: 0;
  transform: translateX(0);
}

.Bluelink {
  position: relative;
  color: inherit;
  text-decoration: none;
  display: inline-block;
}

.Bluelink::before {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 50%;
  background-color: #164fa1;
  transition: all 0.4s ease;
  transform: translateX(-50%);
}

.Bluelink:hover::before {
  width: 100%;
  left: 0;
  transform: translateX(0);
}

.imgHL:hover {
  scale: 0.85;
}

.scaleImg {
  transition: all 0.2s ease;
  overflow: hidden;
}

.scaleImg:hover {
  scale: 1.05;
  border-radius: 12pxs;
}

.veiwMore,
.cardHover,
.imgHL {
  transition: all 0.2s ease;
}

.veiwMore:hover {
  scale: 1.15;
}

.cardHover:hover {
  background: linear-gradient(180deg, #ffeddd 0%, #f5f5f5 100%) !important;
}

/* .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #a7aeb699 !important;
} */

.MuiButtonBase-root:disabled {
  cursor: not-allowed;
  pointer-events: auto;
}

.menuHover .displayModal {
  /* color: red; */
  display: none;
}

.menuHover:hover .displayModal {
  display: block;
}

.hideScrollbar::-webkit-scrollbar {
  display: none !important;
}

::-webkit-scrollbar {
  height: 8px !important;
  width: 8px !important;
  display: flex !important;
}

::-webkit-scrollbar-track {
  background: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background: #f57c00;
  border-radius: 15px;
}

::-webkit-scrollbar-thumb:hover {
  background: #f57c00;
  cursor: pointer;
}

/* 
.ampersand {
  font-family: Snugle !important;
  font-weight: 700;
} */